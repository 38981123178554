import { watchEffect, reactive, computed } from 'vue'
import { useRouter, useRoute } from 'vue-router'
import Routes, { RouteNames } from '@/router/routes'
import {
  useFinancialReportsStore,
  ReportIds as financialReportIds,
} from '@/stores/reports/financial/FinancialReportStore'
import {
  useAuditReportsStore,
  ReportIds as auditReportIds,
} from '@/stores/reports/audit/AuditReportsStore'
import { isNullish } from '@/utils/comparators'
import { useConfigurationStore } from '@/stores/configuration/ConfigurationStore'

const routes = reactive({
  items: [],
})

export const useBreadcrumb = () => {
  const { currentRoute } = useRouter()
  const routeModel = useRoute()
  const configurationStore = useConfigurationStore()
  const buildBreadcrumb = (item, currRoute) => {
    // eslint-disable-next-line no-unused-vars
    const { ers, sr, crs, ifr, tskr, cpr, ar, etskr, pmatr, nr, attachEmail, ...query } =
      currRoute.query

    if (isNullish(item)) {
      return
    }

    if (
      item.name === RouteNames.TasksInbox &&
      currRoute.params.title &&
      currRoute.params.name
    ) {
      return {
        label: currRoute.params.title,
        to: { name: currRoute.params.name },
      }
    }

    return {
      label: item.title,
      to: { name: item.name, query: item.name === RouteNames.ClaimOverview ? query : {} },
      notClickable: item.notClickable,
    }
  }

  const getRouteFromConfiguration = (routeName) => {
    return Routes.find((x) => x.name === routeName)
  }

  const isRootSearchRoute = (route) => routeModel.query.sr && isNullish(route.parent)

  const isRootNotificationsRoute = (route) =>
    routeModel.query.nr && isNullish(route.parent)

  const isPendingMATasksRoute = (route) =>
    routeModel.query.pmatr && isNullish(route.parent)

  const isClaimSearchsReportRoute = (route) =>
    routeModel.query.crs && isNullish(route.parent)

  const isEventReportReportRoute = (route) =>
    routeModel.query.ers && isNullish(route.parent)

  const isFinancialReportRoute = (route) =>
    routeModel.query.ifr && isNullish(route.parent)

  const isTaskReportRoute = (route) => routeModel.query.tskr && isNullish(route.parent)

  const isEmailTaskReportRoute = (route) =>
    routeModel.query.etskr && isNullish(route.parent)

  const isPaymentReportRoute = (route) => routeModel.query.cpr && isNullish(route.parent)

  const isAuditReportRoute = (route) => routeModel.query.ar && isNullish(route.parent)

  const isAttachEmailRoute = (route) =>
    routeModel.query.attachEmail && isNullish(route.parent)

  watchEffect(() => {
    const result = []
    let routeName = currentRoute.value.name
    let maxdepth = 5

    if (isNullish(routeName)) {
      return
    }

    do {
      let route = getRouteFromConfiguration(routeName)

      if (isRootSearchRoute(route)) {
        route = getRouteFromConfiguration(RouteNames.Search)
      }

      if (isRootNotificationsRoute(route)) {
        route = getRouteFromConfiguration(RouteNames.Notifications)
      }

      if (isPendingMATasksRoute(route)) {
        route = getRouteFromConfiguration(RouteNames.PendingManagerApprovalTasks)
      }

      if (isClaimSearchsReportRoute(route)) {
        if (routeName !== RouteNames.Reporting)
          route = getRouteFromConfiguration(RouteNames.ClaimReport)
      }

      if (isFinancialReportRoute(route)) {
        if (routeName !== RouteNames.Reporting) {
          const financialReportsStore = useFinancialReportsStore()
          const currReport = financialReportsStore.getSelectedReport

          const reportRouteMap = {
            [financialReportIds.Subclaims]: RouteNames.FinancialReportsSubclaim,
            [financialReportIds.Items]: RouteNames.FinancialReportsItem,
            [financialReportIds.Claims]: RouteNames.FinancialReportsClaim,
          }

          route = getRouteFromConfiguration(reportRouteMap[currReport])
        }
      }

      if (isTaskReportRoute(route)) {
        if (routeName !== RouteNames.Reporting)
          route = getRouteFromConfiguration(RouteNames.TaskReport)
      }

      if (isEventReportReportRoute(route)) {
        if (routeName !== RouteNames.Reporting)
          route = getRouteFromConfiguration(RouteNames.EventReport)
      }

      if (isEmailTaskReportRoute(route)) {
        if (routeName !== RouteNames.Reporting)
          route = getRouteFromConfiguration(RouteNames.EmailTaskReport)
      }

      if (isPaymentReportRoute(route)) {
        if (routeName !== RouteNames.Reporting)
          route = getRouteFromConfiguration(RouteNames.Payment)
      }

      if (isAuditReportRoute(route)) {
        if (routeName !== RouteNames.Reporting) {
          const auditReportsStore = useAuditReportsStore()
          const report = auditReportsStore.getSelectedReport

          const routeName = (() => {
            if (report === auditReportIds.Claim) return RouteNames.ClaimAuditReport
            else if (report === auditReportIds.Admin) return RouteNames.AdminAuditReport
            else if (report === auditReportIds.Email) return RouteNames.EmailAuditReport
            else return RouteNames.AccessAuditReport
          })()

          route = getRouteFromConfiguration(routeName)
        }
      }

      if (isAttachEmailRoute(route)) {
        const emailInboxRoute = getRouteFromConfiguration(RouteNames.EmailInbox)
        route = { ...emailInboxRoute, title: emailInboxRoute.meta.titleWhenAttachToClaim }
      }

      const breadcrumb = buildBreadcrumb(route, currentRoute.value)

      if (route.parent === RouteNames.Configuration) {
        const featureId = currentRoute.value?.params?.id
        let feature = {}

        if (route?.name === RouteNames.FeatureConfiguration) {
          feature = configurationStore.getFeatureConfigurationNames.find(
            (x) => x.id.toString() == featureId
          )
        } else {
          feature = configurationStore.getSystemConfigurationNames.find(
            (x) => x.id.toString() == featureId
          )
        }

        result.push({
          label: feature?.label,
          to: {},
        })
      }

      result.push(breadcrumb)

      const createAdminBreadcrumb = (route) => {
        return {
          label: route.menuLabel,
          to: {},
          notClickable: true,
        }
      }

      if (route.parent === RouteNames.Administration) {
        result.push(createAdminBreadcrumb(route))
      }

      routeName = route.parent
      maxdepth--
    } while (maxdepth >= 0 && !isNullish(routeName))

    routes.items = result.reverse()
  })

  const getItems = computed(() => {
    return routes.items
  })

  return {
    getItems,
  }
}
