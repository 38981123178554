// @ts-check
import axios from 'axios'
import qs from 'qs'
import { useApplicationStore } from '@/stores/ApplicationStore'
import { useApplicationStoreNotPersistant } from '@/stores/ApplicationStoreNotPersistant'
import { useErrorHandler } from '@/services/errors/useErrorHandler'
import { getConfig } from '@/services/useConfig'
import { useLogger } from '@/services/useLogger'

export const useRequest = () => {
  const applicationStore = useApplicationStore()
  const applicationStoreNotPersistant = useApplicationStoreNotPersistant()
  const { handleRequestError, isErrorHandled } = useErrorHandler()

  /**
   * A wrapper around blocking-requests to show the screan blocking overlay.
   * Shows the screen blocking overlay once for all requests to reduce flickering issues.
   * NOTE: Shows the screen blocking overlay AND the spinner.
   * Throws error when any of the promises rejects, with this first rejection reason
   * @param {...Promise} requests
   * @returns {Promise<Array>}
   */
  const requestsWrapper = async (...requests) => {
    let requestId

    try {
      requestId = applicationStoreNotPersistant.setIsRequestRunning()
      return await Promise.all(requests)
    } catch (error) {
      const log = useLogger()
      log.error('useRequest: requestsWrapper', { requestId, error })
      throw error
    } finally {
      applicationStoreNotPersistant.requestFinished(requestId)
    }
  }

  /**
   * A wrapper around blocking-requests to show the screan blocking overlay.
   * Shows the screen blocking overlay once for all requests to reduce flickering issues.
   * Does NOT throw an error if any of the promises rejects.
   * NOTE: Shows the screen blocking overlay WITHOUT the spinner.
   * Implemented specifally for dashboard screen, since each chart has its own spinner there.
   * @param {...Promise} requests
   * @returns {Promise<Array>}
   */
  const requestsWrapperWithoutSpinner = async (...requests) => {
    let requestId

    try {
      requestId = applicationStoreNotPersistant.setIsRequestRunning(false)
      return await Promise.all(requests)
    } catch (error) {
      const log = useLogger()
      log.error('useRequest: requestsWrapperWithoutSpinner', { requestId, error })
    } finally {
      applicationStoreNotPersistant.requestFinished(requestId)
    }
  }

  /**
   *
   * @param {string} url
   * @param {any} data
   * @param {boolean} [throwError]
   * @param {boolean} [handleError]
   * @param {string} [errorMesssage]
   * @param {string} [contentType]
   * @param {Record<string, string | number | boolean>} [params]
   * @param {boolean} [asBackgroundRequest]
   * @param {AbortSignal} [abortSignal]
   * @returns {Promise<any | undefined>}
   */
  const post = async (
    url,
    data,
    throwError,
    handleError,
    errorMesssage,
    contentType,
    params,
    asBackgroundRequest = false,
    abortSignal
  ) => {
    const callback = () =>
      axios.post(url, data, {
        headers: contentType && {
          'Content-Type': contentType,
        },
        signal: abortSignal,
        params,
      })

    if (asBackgroundRequest)
      return await makeBackgroundRequest(callback, url, throwError, handleError)
    return await makeRequest(callback, url, throwError, handleError, errorMesssage)
  }

  /**
   *
   * @param {string} url
   * @param {any} data
   * @param {boolean} [throwError]
   * @param {boolean} [asBackgroundRequest]
   * @returns {Promise<any | undefined>}
   */
  const patch = async (url, data, throwError, asBackgroundRequest = false) => {
    const callback = () => axios.patch(url, data)

    if (asBackgroundRequest) return await makeBackgroundRequest(callback, url, throwError)
    return await makeRequest(callback, url, throwError)
  }

  /**
   *
   * @param {string} url
   * @param {any} data
   * @param {boolean} [throwError]
   * @param {string} [contentType]
   * @param {boolean} [asBackgroundRequest]
   * @returns {Promise<any | undefined>}
   */
  const put = async (url, data, throwError, contentType, asBackgroundRequest = false) => {
    const callback = () =>
      axios.put(
        url,
        data,
        contentType && {
          headers: {
            'Content-Type': contentType,
          },
        }
      )

    if (asBackgroundRequest) return await makeBackgroundRequest(callback, url, throwError)
    return await makeRequest(callback, url, throwError)
  }

  /**
   *
   * @param {string} url
   * @param {any} [params]
   * @param {boolean} [throwError]
   * @param {boolean} [handleError]
   * @param {boolean} [asBackgroundRequest]
   * @param {AbortSignal} [abortSignal]
   * @returns {Promise<any | undefined>}
   */
  const get = async (
    url,
    params = null,
    throwError,
    handleError,
    asBackgroundRequest = false,
    abortSignal
  ) => {
    const callback = () =>
      axios.get(url, {
        params: params,
        paramsSerializer: {
          serialize: (params) => {
            if (params) {
              const result = qs.stringify(params, { allowDots: true })
              return result
            }
          },
        },
        signal: abortSignal,
      })

    if (asBackgroundRequest)
      return await makeBackgroundRequest(callback, url, throwError, handleError)
    return await makeRequest(callback, url, throwError, handleError)
  }

  /**
   *
   * @param {string} url
   * @param {any} [params]
   * @param {boolean} throwError
   * @param {boolean} [asBackgroundRequest]
   * @returns {Promise<any | undefined>}
   */
  const del = async (
    url,
    params = null,
    throwError = true,
    asBackgroundRequest = false
  ) => {
    const callback = () =>
      axios.delete(url, {
        params: params,
        paramsSerializer: {
          serialize: (params) => {
            if (params) {
              const result = qs.stringify(params, { allowDots: true })
              return result
            }
          },
        },
      })

    if (asBackgroundRequest) return await makeBackgroundRequest(callback, url)
    return await makeRequest(callback, url, throwError)
  }

  /**
   *
   * @param {string } url
   * @param {boolean} [asBackgroundRequest]
   * @returns {Promise<any | undefined>}
   */
  const download = async (
    url,
    asBackgroundRequest = false,
    throwError = false,
    handleError = false
  ) => {
    const callback = () =>
      axios({
        url: url,
        method: 'GET',
        responseType: 'blob',
      })

    if (asBackgroundRequest)
      return await makeBackgroundRequest(callback, url, throwError, handleError)
    return await makeRequest(callback, url, throwError, handleError)
  }

  /**
   *
   * @param {Function} callback
   * @param {string} url
   * @param {boolean} [throwError]
   * @param {boolean} [handleError]
   * @param {string} [errorMesssage]
   * @returns {Promise<any | undefined>} The response data if succesfull
   */
  const makeRequest = async (
    callback,
    url,
    throwError = true,
    handleError = true,
    errorMesssage = ''
  ) => {
    let requestId

    try {
      const config = getConfig()
      const log = useLogger()

      axios.defaults.baseURL = config.api.url
      requestId = applicationStoreNotPersistant.setIsRequestRunning()

      log.info('makeRequest', { url, requestId })

      const token = applicationStore.accessToken
      axios.defaults.headers.common['Authorization'] = `Bearer ${token}`

      const response = await callback()
      return response.data
    } catch (error) {
      if (handleError) {
        await handleRequestError({ error }, errorMesssage)
        error.isHandled = isErrorHandled({ error })
      }

      if (throwError) throw error
    } finally {
      applicationStoreNotPersistant.requestFinished(requestId)
    }
  }

  /**
   *
   * @param {Function} callback
   * @param {string} url
   * @param {boolean} [throwError]
   * @param {boolean} [handleError]
   * @returns {Promise<any | undefined>} The response data if succesfull
   */
  const makeBackgroundRequest = async (callback, url, throwError, handleError) => {
    const log = useLogger()

    try {
      const config = getConfig()

      axios.defaults.baseURL = config.api.url
      log.info('Background request', url)

      const token = applicationStore.accessToken
      axios.defaults.headers.common['Authorization'] = `Bearer ${token}`

      const response = await callback()
      return response.data
    } catch (error) {
      log.error('Background request error', error)

      if (handleError) {
        await handleRequestError({ error })
        error.isHandled = isErrorHandled({ error })
      }

      if (throwError) throw error
    }
  }

  /**
   *
   * @param {string} url
   * @param {any} data
   * @returns {Promise<any | undefined>} The response data if succesfull
   */
  const backgroundPostRequest = async (url, data) => {
    const config = getConfig()
    const log = useLogger()

    axios.defaults.baseURL = config.api.url
    log.info('Background post request', url)

    const token = applicationStore.accessToken
    axios.defaults.headers.common['Authorization'] = `Bearer ${token}`

    const response = await axios.post(url, data)
    return response.data
  }

  /**
   * @typedef {object} BackgroundGetOptions
   * @property {any} [params]
   * @property {boolean} [throwError]
   * @property {boolean} [handleError]
   * @property {boolean} [asBackgroundRequest]
   * @property {(response: any) => (Promise<void> | void)} [onSuccess] Callback function to be
   * called on successful response. This is used for running additional logic after the response
   * before updating the isLoadingStatus.
   */

  /**
   * Wrapper function to do a background get request that handles:
   * - updating the isLoadingStatus and abortControllers with the key argument
   * - passing correct parameters to the get function for background request
   * - cancelling the previous request if it exists
   *
   * Use options.onSuccess to run additional logic after the response before updating the
   * isLoadingStatus.
   * @param {string} url
   * @param {Record<string, AbortController | null>} abortControllers
   * @param {Record<string, boolean>} isLoadingStatus
   * @param {string} key - The key of the abort controller and isLoadingStatus
   * @param {BackgroundGetOptions} [options] - Additional options for the get request
   * @returns {Promise<any | undefined>}
   */
  const backgroundGet = async (
    url,
    abortControllers,
    isLoadingStatus,
    key,
    options = {}
  ) => {
    const {
      params = {},
      throwError = false,
      handleError = true,
      asBackgroundRequest = true,
      onSuccess = null,
    } = options

    if (abortControllers[key]) {
      abortControllers[key].abort()
    }

    const abortController = new AbortController()
    abortControllers[key] = abortController
    isLoadingStatus[key] = true

    let response = null

    try {
      response = await get(
        url,
        params,
        throwError,
        handleError,
        asBackgroundRequest,
        abortController.signal
      )

      if (onSuccess) {
        await onSuccess(response)
      }
    } finally {
      isLoadingStatus[key] = false
    }

    return response
  }

  /**
   * @typedef {object} BackgroundPostOptions
   * @param {any} data
   * @param {boolean} [throwError]
   * @param {boolean} [handleError]
   * @param {string} [contentType]
   * @param {Record<string, string | number | boolean>} [params]
   * @param {boolean} [asBackgroundRequest]
   */

  /**
   * Wrapper function to do a background post request. Handles updating the isLoadingStatus and abortControllers with the
   * key argument and passes correct parameters to the post function for background request.
   * @param {string} url
   * @param {Record<string, AbortController | null>} abortControllers
   * @param {Record<string, boolean>} isLoadingStatus
   * @param {string} key - The key of the abort controller and isLoadingStatus
   * @param {BackgroundPostOptions} options
   * @returns {Promise<any | undefined>}
   */
  const backgroundPost = async (url, abortControllers, isLoadingStatus, key, options) => {
    const {
      data,
      throwError = false,
      handleError = true,
      contentType,
      params = {},
      asBackgroundRequest = true,
    } = options

    if (abortControllers[key]) {
      abortControllers[key].abort()
    }

    const abortController = new AbortController()
    abortControllers[key] = abortController
    isLoadingStatus[key] = true
    let response = null

    try {
      response = await post(
        url,
        data,
        throwError,
        handleError,
        undefined,
        contentType,
        params,
        asBackgroundRequest,
        abortController.signal
      )
    } finally {
      isLoadingStatus[key] = false
    }

    return response
  }

  return {
    post,
    get,
    download,
    put,
    patch,
    del,
    requestsWrapper,
    requestsWrapperWithoutSpinner,
    backgroundPostRequest,
    backgroundGet,
    backgroundPost,
  }
}
