export const Stores = {
  AdminPolicyStore: 'AdminPolicyStore',
  AlertsStore: 'AlertsStore',
  ApplicationStaticDataStore: 'ApplicationStaticDataStore',
  ApplicationStore: 'ApplicationStore',
  ApplicationStoreNotPersistant: 'ApplicationStoreNotPersistant',
  ApprovalLimitsAdminStore: 'ApprovalLimitsAdminStore',
  EmailInboxStore: 'EmailInboxStore',
  EmailInboxViewPreferencesStore: 'EmailInboxViewPreferencesStore',
  EmailTaskReportStore: 'EmailTaskReportStore',
  EmailTemplatesAdminStore: 'EmailTemplatesAdminStore',
  AuditClaimReportStore: 'AuditClaimReportStore',
  AuditAdminReportStore: 'AuditAdminReportStore',
  AuditAccessReportStore: 'AuditAccessReportStore',
  AuditEmailReportStore: 'AuditEmailReportStore',
  AuditReportsStore: 'AuditReportsStore',
  ClaimApprovalLimitsStore: 'ClaimApprovalLimitsStore',
  ClaimDetailsStore: 'ClaimDetailsStore',
  ClaimOverviewStore: 'ClaimOverviewStore',
  ClaimReportStore: 'ClaimReportStore',
  ClaimTasksStore: 'ClaimTasksStore',
  ClaimEmailsStore: 'ClaimEmailsStore',
  ClaimsMasterDataStore: 'ClaimsMasterDataStore',
  ClaimParticipantsStore: 'ClaimParticipantsStore',
  ClassOfBusinessAdminStore: 'ClassOfBusinessAdminStore',
  ConfigurationStore: 'ConfigurationStore',
  CreateClaimStore: 'CreateClaimStore',
  DashboardStore: 'DashboardStore',
  DocumentsStore: 'DocumentsStore',
  FinancialsStore: 'FinancialsStore',
  FinancialReportsStore: 'FinancialReportsStore',
  HolidaysStore: 'HolidaysStore',
  ItemsReportStore: 'ItemsReportStore',
  ClaimsReportStore: 'ClaimsReportStore',
  NonBureauTaskStore: 'NonBureauTaskStore',
  NotesStore: 'NotesStore',
  NotificationsStore: 'NotificationsStore',
  OptionsConfigurationAdminStore: 'OptionsConfigurationAdminStore',
  OptionsStore: 'OptionsStore',
  OrgStructureStore: 'OrgStructureStore',
  ParticipantsAdminStore: 'ParticipantsAdminStore',
  ParticipantsExpertsAdminStore: 'ParticipantsExpertsAdminStore',
  EventsManagementAdminStore: 'EventsManagementAdminStore',
  PaymentReportStore: 'PaymentReportStore',
  EventReportStore: 'EventReportStore',
  PaymentsConfigurationAdminStore: 'PaymentsConfigurationAdminStore',
  PendingManagerApprovalTasksStore: 'PendingManagerApprovalTasksStore',
  PolicyStore: 'PolicyStore',
  RagStore: 'RagStore',
  RemoteCustomTableSettingsStore: 'RemoteCustomTableSettingsStore',
  RemoteTableSettingsStore: 'RemoteTableSettingsStore',
  ReportWatermarkStore: 'ReportWatermarkStore',
  ReserveAndPaymentStore: 'ReserveAndPaymentStore',
  RoleBasedPerformerStore: 'RoleBasedPerformerStore',
  RolesAdminStore: 'RolesAdminStore',
  SearchFiltersStore: 'SearchFiltersStore',
  SearchStore: 'SearchStore',
  SubclaimsReportStore: 'SubclaimsReportStore',
  SubclaimsStore: 'SubclaimsStore',
  SubclaimTypesAdminStore: 'SubclaimTypesAdminStore',
  TableSettingStore: 'TableSettingStore',
  TaskReportStore: 'TaskReportStore',
  UsersAdminStore: 'UsersAdminStore',
  WorkflowStore: 'WorkflowStore',
  ClassOfBusinessGroupStore: 'ClassOfBusinessGroupStore',
  RuleConditionConfigurationAdminStore: 'RuleConditionConfigurationAdminStore',
  SanctionsBeneficiary: 'SanctionsBeneficiary',
}
