// @ts-check
import { ReportIds as FinancialReportIds } from '@/stores/reports/financial/FinancialReportStore'
import { ReportIds as AuditReportIds } from '@/stores/reports/audit/AuditReportsStore'
import { i18n } from '@/plugins/useVueI18n'

const OrgStructure = () =>
  import('@/components/pages/admin/org-structure/OrgStructure.vue')
const CreateNonBureauClaim = () =>
  import('@/components/pages/non-bureau/claims/create/CreateClaim.vue')
const ClaimOverviewWrapper = () =>
  import('@/components/pages/non-bureau/claims/view/ClaimOverviewWrapper.vue')
const TasksInbox = () => import('@/components/pages/non-bureau/tasks/TasksInbox.vue')
const EmailInbox = () => import('@/components/pages/non-bureau/mails/EmailInbox.vue')
const Search = () => import('@/components/pages/non-bureau/search/Search.vue')
const UsersAdministration = () =>
  import('@/components/pages/admin/users/UsersAdministration.vue')
const RolesAdministration = () =>
  import('@/components/pages/admin/roles/RolesAdministration.vue')
const AuditReport = () =>
  import('@/components/pages/non-bureau/reports/audit/AuditReport.vue')
const ClaimReport = () =>
  import('@/components/pages/non-bureau/reports/claim/ClaimReport.vue')
const EventReport = () =>
  import('@/components/pages/non-bureau/reports/event/EventReport.vue')
const TaskReport = () =>
  import('@/components/pages/non-bureau/reports/task/TaskReport.vue')
const EmailTaskReport = () =>
  import('@/components/pages/non-bureau/reports/email-task/EmailTaskReport.vue')
const FinancialReport = () =>
  import('@/components/pages/non-bureau/reports/financial/FinancialReport.vue')
const PaymentReport = () =>
  import('@/components/pages/non-bureau/reports/payment/PaymentReport.vue')
const Notifications = () =>
  import('@/components/pages/non-bureau/notifications/Notifications.vue')
const PendingManagerApprovalTasks = () =>
  import('@/components/pages/non-bureau/pending-ma-tasks/PendingManagerApprovalTasks.vue')
const Dashboard = () => import('@/components/pages/non-bureau/dashboard/Dashboard.vue')
const ClaimsMasterDataAdministration = () =>
  import('@/components/pages/admin/master-data/ClaimsMasterDataAdministration.vue')
const RagStatus = () => import('@/components/pages/admin/rag-status/RagStatus.vue')
const ClassOfBusinessAdministration = () =>
  import('@/components/pages/admin/class-of-business/ClassOfBusinessAdministration.vue')
const ApprovalLimitsAdministration = () =>
  import('@/components/pages/admin/approval-limits/ApprovalLimitsAdministration.vue')
const SubclaimsAdministration = () =>
  import('@/components/pages/admin/subclaim/SubclaimAdministration.vue')
const ParticipantsExpertsAdministration = () =>
  import(
    '@/components/pages/admin/participants-experts/ParticipantsExpertsAdministration.vue'
  )
const EventsManagementAdministration = () =>
  import('@/components/pages/admin/events-management/EventsManagementAdministration.vue')
const PaymentsAdministration = () =>
  import('@/components/pages/admin/payments/PaymentsAdministration.vue')
const ClassOfBusinessGroupAdministration = () =>
  import(
    '@/components/pages/admin/class-of-business-group/ClassOfBusinessGroupAdministration.vue'
  )
const OptionsConfigurationAdministration = () =>
  import(
    '@/components/pages/admin/options-configuration/OptionsConfigurationAdministration.vue'
  )
const SystemConfigurationWrapper = () =>
  import(
    '@/components/pages/configuration/system-configuration/SystemConfigurationWrapper.vue'
  )
const FeatureConfigurationWrapper = () =>
  import(
    '@/components/pages/configuration/feature-configuration/FeatureConfigurationWrapper.vue'
  )
const SilentRenew = () => import('@/components/auth/SilentRenew.vue')
const RedirectCallback = () => import('@/components/auth/RedirectCallback.vue')
const HolidayAdministration = () =>
  import('@/components/pages/admin/holidays/HolidaysAdministration.vue')
const WorkflowAdministration = () =>
  import('@/components/pages/admin/workflow/WorkflowAdministration.vue')
const EmailTemplatesAdministration = () =>
  import('@/components/pages/admin/email-templates/EmailTemplatesAdministration.vue')
const Docoinsights = () =>
  import('@/components/pages/admin/docoinsights/Docoinsights.vue')
const BureauClaimOverview = () =>
  import('@/components/pages/bureau/BureauClaimOverview.vue')
const ArchiveTasks = () =>
  import('@/components/pages/support/archive-tasks/ArchiveTasks.vue')
const ViewProfile = () => import('@/components/pages/user-profile/ViewProfile.vue')

const { t } = i18n.global

export const RouteNames = {
  OrgStructure: 'OrgStructure',
  CreateNonBureauClaim: 'CreateNonBureauClaim',
  TasksInbox: 'TasksInbox',
  EmailInbox: 'EmailInbox',
  Search: 'Search',
  ClaimOverview: 'ClaimOverview',
  UserAdministration: 'UserAdministration',
  RoleAdministration: 'RoleAdministration',
  Audit: 'Audit',
  ClaimAuditReport: 'ClaimAuditReport',
  AdminAuditReport: 'AdminAuditReport',
  AccessAuditReport: 'AccessAuditReport',
  EmailAuditReport: 'EmailAuditReport',
  Payment: 'Payment',
  PendingManagerApprovalTasks: 'PendingManagerApprovalTasks',
  Notifications: 'Notifications',
  Dashboard: 'Dashboard',
  ClaimsMasterDataAdministration: 'ClaimsMasterDataAdministration',
  RagStatus: 'RagStatus',
  ClassOfBusinessAdministration: 'ClassOfBusinessAdministration',
  ApprovalLimitsAdministration: 'ApprovalLimitsAdministration',
  SubclaimsAdministration: 'SubclaimsAdministration',
  ParticipantsExpertsAdministration: 'ParticipantsExpertsAdministration',
  EventsManagementAdministration: 'EventsManagementAdministration',
  PaymentsAdministration: 'PaymentsAdministration',
  ClassOfBusinessGroupAdministration: 'ClassOfBusinessGroupAdministration',
  FeatureConfiguration: 'FeatureConfiguration',
  SystemConfiguration: 'SystemConfiguration',
  OptionsConfigurationAdministration: 'OptionsConfigurationAdministration',
  Administration: 'Administration',
  Configuration: 'Configuration',
  Reporting: 'Reporting',
  ClaimReport: 'ClaimReport',
  EventReport: 'EventReport',
  FinancialReports: 'FinancialReports',
  FinancialReportsSubclaim: 'FinancialReportsSubclaim',
  FinancialReportsItem: 'FinancialReportsItem',
  FinancialReportsClaim: 'FinancialReportsClaim',
  TaskReport: 'TaskReport',
  EmailTaskReport: 'EmailTaskReport',
  SilentRenew: 'SilentRenew',
  RedirectCallback: 'RedirectCallback',
  HolidayAdministration: 'HolidayAdministration',
  WorkflowAdministration: 'WorkflowAdministration',
  EmailTemplatesAdministration: 'EmailTemplatesAdministration',
  Docoinsights: 'Docoinsights',
  SupportServices: 'SupportServices',
  ArchiveTasks: 'ArchiveTasks',
  BureauClaimOverview: 'BureauClaimOverview',
  TriageAdministration: 'TriageAdministration',
  AlertAdministration: 'AlertAdministration',
  ViewProfile: 'ViewProfile',
}

export const RouteItems = {
  [RouteNames.SilentRenew]: {
    title: t('navigation.silentRenew'),
    name: RouteNames.SilentRenew,
    path: '/silent-renew',
    component: SilentRenew,
  },
  [RouteNames.RedirectCallback]: {
    title: t('navigation.redirectCallback'),
    name: RouteNames.RedirectCallback,
    path: '/callback-new',
    component: RedirectCallback,
  },
  [RouteNames.OrgStructure]: {
    title: t('common.organizationStructureAdministration'),
    icon: 'users',
    name: RouteNames.OrgStructure,
    path: '/org-structure',
    component: OrgStructure,
    parent: RouteNames.Administration,
    menuLabel: t('common.userAdministration'),
  },
  [RouteNames.CreateNonBureauClaim]: {
    title: t('common.createClaim'),
    icon: 'plus',
    name: RouteNames.CreateNonBureauClaim,
    path: '/create-non-bureau-claim',
    component: CreateNonBureauClaim,
    parent: RouteNames.TasksInbox,
  },
  [RouteNames.ClaimOverview]: {
    title: t('common.claim'),
    icon: 'clipboard',
    name: RouteNames.ClaimOverview,
    path: '/non-bureau/claim/overview',
    component: ClaimOverviewWrapper,
    parent: RouteNames.TasksInbox,
  },
  Home: {
    path: '/',
    name: RouteNames.TasksInbox,
    component: TasksInbox,
    title: t('common.inbox'),
    icon: 'inbox',
  },
  Empty: {
    path: '',
    name: RouteNames.TasksInbox,
    component: TasksInbox,
    title: t('common.inbox'),
    icon: 'inbox',
  },
  [RouteNames.TasksInbox]: {
    path: '/non-bureau-tasks/outstanding-inbox',
    name: RouteNames.TasksInbox,
    component: TasksInbox,
    title: t('common.inbox'),
    icon: 'inbox',
  },
  [RouteNames.EmailInbox]: {
    path: '/email-inbox',
    name: RouteNames.EmailInbox,
    component: EmailInbox,
    title: t('common.emailInbox'),
    icon: 'mail',
    meta: { titleWhenAttachToClaim: 'Outstanding email' },
  },
  [RouteNames.Search]: {
    path: '/search',
    name: RouteNames.Search,
    component: Search,
    title: t('common.search'),
    icon: 'search',
  },
  [RouteNames.Docoinsights]: {
    title: 'DOCOinsights',
    icon: 'dashicons:dashboard',
    name: RouteNames.Docoinsights,
    path: '/docoinsights',
    component: Docoinsights,
  },
  [RouteNames.SupportServices]: {
    title: t('navigation.supportServices'),
    icon: 'life-buoy',
    name: RouteNames.SupportServices,
    path: '/support-services',
    notClickable: true,
  },
  [RouteNames.ArchiveTasks]: {
    title: t('navigation.archiveTasks'),
    icon: 'life-buoy',
    name: RouteNames.ArchiveTasks,
    path: '/support-services/archive-tasks',
    component: ArchiveTasks,
    parent: RouteNames.SupportServices,
  },
  [RouteNames.BureauClaimOverview]: {
    title: t('common.claim'),
    name: RouteNames.BureauClaimOverview,
    path: '/bureau/claim/overview',
    component: BureauClaimOverview,
    parent: RouteNames.TasksInbox,
  },
  [RouteNames.Audit]: {
    path: '/reporting/audit',
    name: RouteNames.Audit,
    component: AuditReport,
    title: t('common.auditReports'),
    icon: 'align-justify',
    parent: RouteNames.Reporting,
    props: { reportToShow: AuditReportIds.Claim },
  },
  [RouteNames.ClaimAuditReport]: {
    path: '/reporting/audit',
    name: RouteNames.ClaimAuditReport,
    component: AuditReport,
    title: t('common.claimAuditReport'),
    parent: RouteNames.Audit,
    props: { reportToShow: AuditReportIds.Claim },
    meta: { ignoreForSetSelectedMenu: true },
  },
  [RouteNames.AdminAuditReport]: {
    path: '/reporting/audit',
    name: RouteNames.AdminAuditReport,
    component: AuditReport,
    title: t('common.adminAuditReport'),
    parent: RouteNames.Audit,
    props: { reportToShow: AuditReportIds.Admin },
    meta: { ignoreForSetSelectedMenu: true },
  },
  [RouteNames.AccessAuditReport]: {
    path: '/reporting/audit',
    name: RouteNames.AccessAuditReport,
    component: AuditReport,
    title: t('common.accessAuditReport'),
    parent: RouteNames.Audit,
    props: { reportToShow: AuditReportIds.Access },
    meta: { ignoreForSetSelectedMenu: true },
  },
  [RouteNames.EmailAuditReport]: {
    path: '/reporting/audit',
    name: RouteNames.EmailAuditReport,
    component: AuditReport,
    title: t('common.emailAuditReport'),
    parent: RouteNames.Audit,
    props: { reportToShow: AuditReportIds.Email },
    meta: { ignoreForSetSelectedMenu: true },
  },
  [RouteNames.ClaimReport]: {
    path: '/reporting/claim',
    name: RouteNames.ClaimReport,
    component: ClaimReport,
    title: t('common.claimReport'),
    icon: 'align-justify',
    parent: RouteNames.Reporting,
  },
  [RouteNames.EventReport]: {
    path: '/reporting/event',
    name: RouteNames.EventReport,
    component: EventReport,
    title: t('common.eventReport'),
    icon: 'align-justify',
    parent: RouteNames.Reporting,
  },
  [RouteNames.TaskReport]: {
    path: '/reporting/task',
    name: RouteNames.TaskReport,
    component: TaskReport,
    title: t('common.taskReport'),
    icon: 'align-justify',
    parent: RouteNames.Reporting,
  },
  [RouteNames.EmailTaskReport]: {
    path: '/reporting/email-task',
    name: RouteNames.EmailTaskReport,
    component: EmailTaskReport,
    title: t('common.emailTaskDetailsReport'),
    icon: 'align-justify',
    parent: RouteNames.Reporting,
  },
  [RouteNames.Payment]: {
    path: '/reporting/payment',
    name: RouteNames.Payment,
    component: PaymentReport,
    title: t('common.paymentReport'),
    icon: 'align-justify',
    parent: RouteNames.Reporting,
  },
  [RouteNames.Reporting]: {
    title: t('common.reports'),
    to: '#reporting',
    icon: 'layers',
    name: RouteNames.Reporting,
    notClickable: true,
  },
  [RouteNames.FinancialReports]: {
    path: '/reporting/financial-reports',
    name: RouteNames.FinancialReports,
    component: FinancialReport,
    title: t('common.financialReports'),
    icon: 'align-justify',
    parent: RouteNames.Reporting,
  },
  [RouteNames.FinancialReportsSubclaim]: {
    path: '/reporting/financial-reports',
    name: RouteNames.FinancialReportsSubclaim,
    component: FinancialReport,
    title: t('common.subclaimFinancialsReport'),
    parent: RouteNames.FinancialReports,
    props: { reportToShow: FinancialReportIds.Subclaims },
    meta: { ignoreForSetSelectedMenu: true },
  },
  [RouteNames.FinancialReportsItem]: {
    path: '/reporting/financial-reports',
    name: RouteNames.FinancialReportsItem,
    component: FinancialReport,
    title: t('common.itemFinancialsReport'),
    parent: RouteNames.FinancialReports,
    props: { reportToShow: FinancialReportIds.Items },
    meta: { ignoreForSetSelectedMenu: true },
  },
  [RouteNames.FinancialReportsClaim]: {
    path: '/reporting/financial-reports',
    name: RouteNames.FinancialReportsClaim,
    component: FinancialReport,
    title: 'Claim financials report',
    parent: RouteNames.FinancialReports,
    props: { reportToShow: FinancialReportIds.Claims },
    meta: { ignoreForSetSelectedMenu: true },
  },
  [RouteNames.PendingManagerApprovalTasks]: {
    path: '/pending-manager-approval-tasks',
    name: RouteNames.PendingManagerApprovalTasks,
    component: PendingManagerApprovalTasks,
    title: t('common.pendingMATasks'),
    icon: 'material-symbols:pending-actions',
  },
  [RouteNames.Notifications]: {
    path: '/notifications',
    name: RouteNames.Notifications,
    component: Notifications,
    title: t('common.notifications'),
    icon: 'mdi:bell-outline',
  },
  [RouteNames.Dashboard]: {
    path: '/dashboard',
    name: RouteNames.Dashboard,
    component: Dashboard,
    title: t('common.dashboard'),
    icon: 'material-symbols:dashboard-outline-rounded',
  },
  [RouteNames.RagStatus]: {
    path: '/rag-admin',
    name: RouteNames.RagStatus,
    component: RagStatus,
    title: t('common.taskRAGadministration'),
    icon: 'layers',
    parent: RouteNames.Administration,
    menuLabel: t('common.miscellaneousAdministration'),
  },
  [RouteNames.Administration]: {
    title: t('common.administration'),
    to: '#administration',
    icon: 'settings',
    name: RouteNames.Administration,
    notClickable: true,
  },
  [RouteNames.UserAdministration]: {
    title: t('common.user'),
    icon: 'user',
    name: RouteNames.UserAdministration,
    path: '/administration/users-admin',
    component: UsersAdministration,
    parent: RouteNames.Administration,
    menuLabel: t('common.userAdministration'),
  },
  UserRolesAdministrator: {
    title: t('common.rolesAdministration'),
    icon: 'users',
    name: RouteNames.RoleAdministration,
    path: '/administration/roles-admin',
    component: RolesAdministration,
    parent: RouteNames.Administration,
    menuLabel: t('common.userAdministration'),
  },
  [RouteNames.ClaimsMasterDataAdministration]: {
    title: t('common.masterDataAdmin'),
    icon: 'layers',
    name: RouteNames.ClaimsMasterDataAdministration,
    path: '/administration/claims-master-data-admin',
    component: ClaimsMasterDataAdministration,
    parent: RouteNames.Administration,
    menuLabel: t('common.miscellaneousAdministration'),
  },
  [RouteNames.ClassOfBusinessAdministration]: {
    title: t('common.cobAdministration'),
    icon: 'layers',
    name: RouteNames.ClassOfBusinessAdministration,
    path: '/administration/class-of-business-admin',
    component: ClassOfBusinessAdministration,
    parent: RouteNames.Administration,
    menuLabel: t('common.miscellaneousAdministration'),
  },
  [RouteNames.ApprovalLimitsAdministration]: {
    title: t('common.approvalLimitsAdmin'),
    icon: 'clock',
    name: RouteNames.ApprovalLimitsAdministration,
    path: '/administration/approval-limits-admin',
    component: ApprovalLimitsAdministration,
    parent: RouteNames.Administration,
    menuLabel: t('common.miscellaneousAdministration'),
  },
  [RouteNames.Configuration]: {
    title: t('common.configuration'),
    to: '#configuration',
    icon: 'tool',
    name: RouteNames.Configuration,
    notClickable: true,
  },
  [RouteNames.FeatureConfiguration]: {
    title: t('common.featureConfiguration'),
    icon: 'tool',
    name: RouteNames.FeatureConfiguration,
    path: '/configuration/feature-configuration/:id',
    component: FeatureConfigurationWrapper,
    parent: RouteNames.Configuration,
    menuLabel: t('common.featureConfiguration'),
    notClickable: true,
  },
  [RouteNames.SystemConfiguration]: {
    title: t('common.systemConfiguration'),
    icon: 'tool',
    name: RouteNames.SystemConfiguration,
    path: '/configuration/system-configuration/:id',
    component: SystemConfigurationWrapper,
    parent: RouteNames.Configuration,
    menuLabel: t('common.systemConfiguration'),
    notClickable: true,
  },
  OptionsConfiguration: {
    title: t('common.optionsAdministration'),
    icon: 'tool',
    name: RouteNames.OptionsConfigurationAdministration,
    path: '/configuration/options-configuration',
    component: OptionsConfigurationAdministration,
    parent: RouteNames.Administration,
    menuLabel: t('common.rulesAdministration'),
  },
  [RouteNames.SubclaimsAdministration]: {
    title: t('common.subclaimsAdministration'),
    icon: 'layers',
    name: RouteNames.SubclaimsAdministration,
    path: '/administration/subclaim-types',
    component: SubclaimsAdministration,
    parent: RouteNames.Administration,
    menuLabel: t('common.miscellaneousAdministration'),
  },
  [RouteNames.HolidayAdministration]: {
    title: t('common.holidayAdministration'),
    icon: 'clock',
    name: RouteNames.HolidayAdministration,
    path: '/administration/holiday-admin',
    component: HolidayAdministration,
    parent: RouteNames.Administration,
    menuLabel: t('common.miscellaneousAdministration'),
  },
  [RouteNames.WorkflowAdministration]: {
    title: t('common.workflowAdministration'),
    name: RouteNames.WorkflowAdministration,
    path: '/administration/workflow-admin',
    component: WorkflowAdministration,
    parent: RouteNames.Administration,
    menuLabel: t('common.rulesAdministration'),
  },
  [RouteNames.TriageAdministration]: {
    title: t('common.triageAdministration'),
    name: RouteNames.TriageAdministration,
    path: '/administration/triage-admin',
    component: WorkflowAdministration,
    parent: RouteNames.Administration,
    menuLabel: t('common.rulesAdministration'),
  },
  [RouteNames.EmailTemplatesAdministration]: {
    title: t('common.emailTemplatesAdministration'),
    name: RouteNames.EmailTemplatesAdministration,
    path: '/administration/email-templates-admin',
    component: EmailTemplatesAdministration,
    parent: RouteNames.Administration,
    menuLabel: t('common.miscellaneousAdministration'),
  },
  [RouteNames.AlertAdministration]: {
    title: t('common.alertsAdministration'),
    name: RouteNames.AlertAdministration,
    path: '/administration/alert-admin',
    component: WorkflowAdministration,
    parent: RouteNames.Administration,
    menuLabel: t('common.rulesAdministration'),
  },
  [RouteNames.ParticipantsExpertsAdministration]: {
    title: t('common.participantsAdministration'),
    icon: 'clock',
    name: RouteNames.ParticipantsExpertsAdministration,
    path: '/administration/participants-experts-admin',
    component: ParticipantsExpertsAdministration,
    parent: RouteNames.Administration,
    menuLabel: t('common.miscellaneousAdministration'),
  },
  [RouteNames.EventsManagementAdministration]: {
    title: t('common.eventsManagementAdministration'),
    icon: 'layers',
    name: RouteNames.EventsManagementAdministration,
    path: '/administration/events-management-admin',
    component: EventsManagementAdministration,
    parent: RouteNames.Administration,
    menuLabel: t('common.miscellaneousAdministration'),
  },
  [RouteNames.PaymentsAdministration]: {
    title: t('common.paymentAdministration'),
    icon: 'clock',
    name: RouteNames.PaymentsAdministration,
    path: '/administration/payments-admin',
    component: PaymentsAdministration,
    parent: RouteNames.Administration,
    menuLabel: t('common.rulesAdministration'),
  },
  [RouteNames.ClassOfBusinessGroupAdministration]: {
    title: t('common.cobGroupAdministration'),
    icon: 'clock',
    name: RouteNames.ClassOfBusinessGroupAdministration,
    path: '/administration/class-of-business-group',
    component: ClassOfBusinessGroupAdministration,
    parent: RouteNames.Administration,
    menuLabel: t('common.miscellaneousAdministration'),
  },
  [RouteNames.ViewProfile]: {
    path: '/view-profile',
    name: RouteNames.ViewProfile,
    component: ViewProfile,
    title: t('navigation.viewProfile'),
    // icon: 'material-symbols:dashboard-outline-',
  },
}

const Routes = [
  RouteItems.Home,
  RouteItems.Search,
  RouteItems.CreateNonBureauClaim,
  RouteItems.TasksInbox,
  RouteItems.EmailInbox,
  RouteItems.ClaimOverview,
  RouteItems.UserAdministration,
  RouteItems.UserRolesAdministrator,
  RouteItems.ClaimsMasterDataAdministration,
  RouteItems.Empty,
  RouteItems.ClaimReport,
  RouteItems.EventReport,
  RouteItems.Audit,
  RouteItems.ClaimAuditReport,
  RouteItems.AdminAuditReport,
  RouteItems.AccessAuditReport,
  RouteItems.EmailAuditReport,
  RouteItems.Payment,
  RouteItems.PendingManagerApprovalTasks,
  RouteItems.Notifications,
  RouteItems.Dashboard,
  RouteItems.RagStatus,
  RouteItems.ClassOfBusinessAdministration,
  RouteItems.ApprovalLimitsAdministration,
  RouteItems.FeatureConfiguration,
  RouteItems.SystemConfiguration,
  RouteItems.OptionsConfiguration,
  RouteItems.SubclaimsAdministration,
  RouteItems.ParticipantsExpertsAdministration,
  RouteItems.EventsManagementAdministration,
  RouteItems.PaymentsAdministration,
  RouteItems.ClassOfBusinessGroupAdministration,
  RouteItems.OrgStructure,
  RouteItems.HolidayAdministration,
  RouteItems.WorkflowAdministration,
  RouteItems.TriageAdministration,
  RouteItems.EmailTemplatesAdministration,
  RouteItems.Administration,
  RouteItems.Reporting,
  RouteItems.Configuration,
  RouteItems.FinancialReports,
  RouteItems.FinancialReportsSubclaim,
  RouteItems.FinancialReportsItem,
  RouteItems.FinancialReportsClaim,
  RouteItems.TaskReport,
  RouteItems.EmailTaskReport,
  RouteItems.RedirectCallback,
  RouteItems.SilentRenew,
  RouteItems.Docoinsights,
  RouteItems.SupportServices,
  RouteItems.ArchiveTasks,
  RouteItems.BureauClaimOverview,
  RouteItems.AlertAdministration,
  RouteItems.ViewProfile,
]

export default Routes
